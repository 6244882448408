import React from 'react';
import { Authenticator, View, Heading, useTheme } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import '@aws-amplify/ui-react/styles.css';

// Define a flag to control authentication
const isAuthEnabled = false; // Change this to true when you want to enable authentication

if (isAuthEnabled) {
  Amplify.configure(awsconfig);
}

function AuthPage() {
  const { tokens } = useTheme();

  if (!isAuthEnabled) {
    return (
      <View textAlign="center" padding="medium">
        <Heading level={3}>Authentication is currently disabled</Heading>
      </View>
    );
  }

  return (
    <View
      backgroundColor={tokens.colors.background.primary}
      color={tokens.colors.font.primary}
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Authenticator
        socialProviders={['google']}
        loginMechanisms={['email']}
        hideSignUp={true}
      ></Authenticator>
    </View>
  );
}

export default AuthPage;
