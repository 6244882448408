import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import GitLabApi from '../backend/GitlabApi';
import formOptions from './formOptions.json';
import InfoTooltip from '../components/InfoTooltip';
import countryList from 'react-select-country-list';

function PaidUsersCompanionMode() {
    const initialProject = formOptions.projects[0];
    const project = initialProject.name;
    const server = formOptions.servers[0];
    const [userWeight, setUserWeight] = useState(formOptions.userWeight);
    const [userHeight, setUserHeight] = useState(formOptions.userHeight);
    const [fitLvl, setFitLvl] = useState(formOptions.fitlevels[0]);
    const [userWeightTarget, setUserWeightTarget] = useState(formOptions.userWeightTarget);
    const [gender, setGender] = useState(initialProject.genders[0]);
    const [branchId, setBranch] = useState(initialProject.branches[5]);
    const [email, setEmail] = useState('');
    const [bmi, setBmi] = useState(null);
    const [country, setCountry] = useState({value: 'US', label: 'United States'});
    const [flashMessage, setFlashMessage] = useState('');
    const [isPositiveMessage, setIsPositiveMessage] = useState(false);
    const [showFlash, setShowFlash] = useState(false);
    const [webUrl, setWebUrl] = useState('');

    const gitLabApi = new GitLabApi();
    const countries = countryList().getData();


    const handleFlashMessageClick = () => {
        if (webUrl) {
            window.open(webUrl, '_blank');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const selectedProject = formOptions.projects.find(p => p.name === project);
        const gitlabProjectId = selectedProject.gitlabId;
        console.log(`Project: ${project}, Server: ${server}, Branch: ${branchId}, User Weight: ${userWeight}, User Height: ${userHeight}, GitLab Project ID: ${gitlabProjectId}`);

        try {
            const variables = {
                project,
                server,
                user_height: userHeight,
                user_weight: userWeight,
                user_target_weight: userWeightTarget,
                fitness_lvl: fitLvl,
                gender,
                user_email: email,
                country: country ? country.value : '',
                user_creation_flow: 'simplified'
            };

            if (branchId) {
                variables.BRANCH_NAME = branchId;
            }



            const response = await gitLabApi.triggerPipeline({
                gitlabProjectId,
                ref: 'master',
                variables
            });
            console.log(response)
            if (response.status === 201) {
                setFlashMessage('Pipeline triggered successfully.');
                setIsPositiveMessage(true);
                setWebUrl(response.data.web_url);
            } else {
                setFlashMessage('Failed to trigger pipeline.');
                setIsPositiveMessage(false);
            }
            setShowFlash(true);
            setTimeout(() => {
                setShowFlash(false);
            }, 10000);
        } catch (error) {
            console.error('Failed to trigger pipeline:', error);
            setFlashMessage('Error triggering pipeline.');
            setIsPositiveMessage(false);
            setShowFlash(true);
            setTimeout(() => {
                setShowFlash(false);
            }, 5000);
        }
    };

    const calculateBmi = () => {
        if (userWeight && userHeight) {
            const heightInMeters = userHeight / 100;
            const bmiValue = (userWeight / (heightInMeters * heightInMeters)).toFixed(2);
            setBmi(bmiValue);
        } else {
            setBmi(null);
        }
    };

    useEffect(() => {
        calculateBmi();
        //eslint-disable-next-line
    }, [userWeight, userHeight]);


    const selectedProject = formOptions.projects.find(p => p.name === project);
    const genderOptions = selectedProject.genders.map(g => ({value: g, label: g}));
    const branchesOptions = selectedProject.branches.map(g => ({value: g, label: g}));

    return (
        <div className="container">
            <h1 className="title">User For Companion Mode</h1>
            <form onSubmit={handleSubmit}>
                <label>
                    Email<InfoTooltip message="You can register many users for one email by using + symbol in prefix for example ivan.ivanov+1@welltech.com"/>
                    <div className="input-group">
                        <input
                            type="text"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            className="input-field"
                            placeholder="Enter email address"
                        />
                        <button type="button" className="clear-button" onClick={() => setEmail('')}>×</button>
                    </div>
                </label>
                <label>
                    User Gender <InfoTooltip message={`Default value: ${gender}`}/>
                    <div className="input-group">
                        <Select
                            value={{value: gender, label: gender}}
                            onChange={option => setGender(option.value)}
                            options={genderOptions}
                            className="select-field"
                        />
                    </div>
                </label>
                <label>
                    Branch<InfoTooltip message="Select branch"/>
                    <div className="input-group">
                        <Select
                            value={{value: branchId, label: branchId}}
                            onChange={option => setBranch(option.value)}
                            options={branchesOptions}
                            className="select-field"
                        />
                    </div>
                </label>
                {/*<label>*/}
                {/*    Branch Name<InfoTooltip message="Default value: default"/>*/}
                {/*    <div className="input-group">*/}
                {/*        <input*/}
                {/*            type="text"*/}
                {/*            value={branchId}*/}
                {/*            onChange={e => setBranch(e.target.value)}*/}
                {/*            className="input-field"*/}
                {/*            placeholder="Enter branch ID"*/}
                {/*        />*/}
                {/*        <button type="button" className="clear-button" onClick={() => setBranch('')}>×</button>*/}
                {/*    </div>*/}
                {/*</label>*/}
                <label>
                    User Weight<InfoTooltip message={`Default value: ${formOptions.userWeight}`}/>
                    <div className="input-group">
                        <input
                            type="number"
                            value={userWeight}
                            onChange={e => setUserWeight(e.target.value)}
                            onBlur={calculateBmi}
                            className="input-field"
                            placeholder="Enter user's weight"
                        />
                        <button type="button" className="clear-button" onClick={() => setUserWeight('')}>×</button>
                    </div>
                </label>
                <label>
                    User Height<InfoTooltip message={`Default value: ${formOptions.userHeight}`}/>
                    <div className="input-group">
                        <input
                            type="number"
                            value={userHeight}
                            onChange={e => setUserHeight(e.target.value)}
                            onBlur={calculateBmi}
                            className="input-field"
                            placeholder="Enter user's height"
                        />
                        <button type="button" className="clear-button" onClick={() => setUserHeight('')}>×</button>
                    </div>
                </label>
                <label>
                    User Target Weight<InfoTooltip message={`Default value: ${formOptions.userWeightTarget}`}/>
                    <div className="input-group">
                        <input
                            type="number"
                            value={userWeightTarget}
                            onChange={e => setUserWeightTarget(e.target.value)}
                            className="input-field"
                            placeholder="Enter user's target weight"
                        />
                        <button type="button" className="clear-button" onClick={() => setUserWeightTarget('')}>×
                        </button>
                    </div>
                </label>
                <label>
                    Fitness Level <InfoTooltip message={`Default value: ${formOptions.fitlevels[0]}`}/>
                    <div className="input-group">
                        <select value={fitLvl} onChange={e => setFitLvl(e.target.value)} className="select-field">
                            {formOptions.fitlevels.map(f => (
                                <option key={f} value={f}>{f}</option>
                            ))}
                        </select>
                    </div>
                </label>
                <label>
                    Country <InfoTooltip message="Select your country"/>
                    <div className="input-group">
                        <Select
                            name="Country"
                            value={country}
                            onChange={setCountry}
                            options={countries}
                            className="select-field"
                        />
                    </div>
                </label>
                <button type="submit" className="button">Trigger Job</button>
            </form>
            {showFlash && (
                <div className={`flash-message ${isPositiveMessage ? 'positive' : 'negative'}`}
                     onClick={handleFlashMessageClick}>
                    {flashMessage}
                </div>
            )}
        </div>
    );
}

export default PaidUsersCompanionMode;
