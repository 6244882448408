import React from 'react';
import {Link} from 'react-router-dom';
import {useAuthenticator} from '@aws-amplify/ui-react';

function Sidebar({onClose}) {
    const {signOut} = useAuthenticator();
    return (
        <div className="sidebar">
            <div className="sideblock">
                <Link to="/">
                    <button onClick={onClose}>Home</button>
                </Link>
                <Link to="/paid-user">
                    <button onClick={onClose}>Paid Users Creation</button>
                </Link>
                <Link to="/suites-run">
                    <button onClick={onClose}>Suite Run</button>
                </Link>
                <Link to="/spec-run">
                    <button onClick={onClose}>Spec Run</button>
                </Link>
                <Link to="/spec-run-backend">
                    <button onClick={onClose}>Spec Run (Backend Only)</button>
                </Link>
                <Link to="/yoga-otp">
                    <button onClick={onClose}>Yoga OTP password</button>
                </Link>
                <Link to="/mbw-otp">
                    <button onClick={onClose}>MB OTP password</button>
                </Link>
                <Link to="/paid-user-companion-mode">
                    <button onClick={onClose} style={{ backgroundColor: 'rgb(248, 217, 123)',
                        color: 'black',
                        fontWeight: 'bold',
                        fontFamily: 'Roboto, sans-serif' }}>Create Yoga user for companion mode</button>
                </Link>
            </div>
            <div className="sideblock">
                <Link>
                    <button onClick={signOut}>Sign out</button>
                </Link>
            </div>
        </div>
    );
}

export default Sidebar;
